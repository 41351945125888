.ImageAndText-container {
    display: flex;
    align-items: center;
}

.ImageAndText-image {
    width: 4vh; /* Переменная для динамической высоты */
    height: 4vh; /* Переменная для динамической высоты */
    margin-right: 1vh;
}

.ImageAndText-text {
    text-align: center;
    color: var(--color_white, 'red'); /* Переменная для динамического цвета */
    font-family: Tahoma, sans-serif;
    font-weight: normal; /* 'normal' эквивалентно 'regular' */
    font-size: 1.5vh;
}
