.footer {
    margin: 1.5vh 15%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "."
        ".";
}

.first-row {
    display: grid;
    grid-template-columns: 6fr 3fr 3fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        ". . .";
}

.grid-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.location-item {
    grid-column: 1;
    /* Текст на первой части */
}

.phone-item {
    grid-column: 2;
    /* Компонент на пятой части */
}

.mail-item {
    grid-column: 3;
    /* Компонент на восьмой части */
}

.company-name {
    text-align: center;
    color: white;
    font-size: 1.5vh;
    font-family: Tahoma;
    font-weight: 400;
    line-height: 3vh;
    word-wrap: break-word
}