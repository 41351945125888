.VerticalCard-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  height: 100%
}

.VerticalCard-card-image {
  max-width: 100%;
  height: 15vh;
  margin-bottom: 20px;
  object-fit: cover;
}

.VerticalCard-card-title {
  font-weight: bold;
  min-height: 50px;
  /* Фиксированная высота для заголовка */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #001419; font-size: 3.5vh; font-family: Tahoma; font-weight: 700;
}

.VerticalCard-card-description {
  width: 100%;
  margin: 0;
  color: #001419;  font-size: 2.5vh; font-family: Tahoma; font-weight: 400;
}