:root {
    /* Colors defines */

    --color_aquamarine_50: #F2FBFD;
    --color_aquamarine_400 : #62CCE4;
    --color_aquamarine_500:  #25B9DA;
    --color_aquamarine_600 : #209FBB;
    --color_aquamarine_700: #197C91;
    --color_aquamarine_700_70: #197b91b2;
    --color_light_blue_750 : #006280;
    --color_white: white;
    --color_black_text: #1A1F24;


    /* Location defines */
    --loc_leftPaddingPrc: 15%;


    /* Assingments */
    --as_header_picTextColor: var(--color_white);
    --as_header_backgroundColor: var(--color_light_blue_750);
    --as_header_reactUsButtonColor: var(--color_aquamarine_400);
    --as_header_reactUsTextColor: var(--color_white);

    --as_we_suggest_cardColor: var(--color_aquamarine_700_70);
    --as_we_suggest_activeDotColor: var(--color_aquamarine_700);
    --as_we_suggest_notActiveDotColor: var(--color_white);
    --as_we_suggest_bgColor: var(--color_aquamarine_500);
    --as_we_suggest_textColor: var(--color_white);
}