.TechnicalSupport_background {
    width: 100%;
    height: 100vh;
    background-color: var(--color_aquamarine_400);
}

.TechnicalSupport_main_container {
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 10fr 2fr;
    grid-auto-columns: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "header"
        "Body"
        ".";

    margin-left: 15%;
    margin-right: 15%;
}

.TechnicalSupport_header {
    grid-area: header;
    text-align: center;
    color: #1A1F24;
    font-size: 3vh;
    font-family: Tahoma;
    font-weight: 700;
    line-height: 40px;
    word-wrap: break-word;
    margin-top: 10vh;
    margin-bottom: 8vh;
}

.TechnicalSupport_Body {
    display: grid;
    grid-template-columns: 3fr 1fr 7fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "Stages LeftArrow Slide RightArrow";
    grid-area: Body;
}

.TechnicalSupport_Slide {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 9fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "SlideBody"
        "Progress";
    grid-area: Slide;
}

.TechnicalSupport_SlideBody {
    grid-area: SlideBody;
    padding: 2vh;
}

.TechnicalSupport_Progress {
    grid-area: Progress;

    margin-top: 3vh;
    display: flex;
    gap: 0.5vw;
    justify-content: center
}


.TechnicalSupport_slide-wrapper {
    background-color: var(--color_white);
    border-radius: 6.5vh;
    height: 100%;
    width: 100%;
    padding-left: 2vh;
}

.TechnicalSupport_slide-content {
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 3vw;
    width: 80%;
    /* height: 45%; */
}


.TechnicalSupport_slide-content h2 {
    margin-bottom: 0px;
    margin-top: 3vh;
    color: var(--color_black_text);
    font-size: 3vh;
    font-family: Tahoma;
    font-weight: 700;
    /* line-height: 40px;  */
    word-wrap: break-word;
}

.TechnicalSupport_slide-content ul {
    padding: 0;

    color: var(--color_black_text);
    font-family: Tahoma;
    /*TODO: Inter*/
    font-weight: 400;
    line-height: 4.5vh;
    word-wrap: break-word;
    font-size: 3vh;
}

.Stages {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 20vh 10vh;
    gap: 0px 0px;
    grid-template-areas:
        "Stages_content"
        "Stages_title";
    align-self: center;
    grid-area: Stages;
}

.Stages_content {
    grid-area: Stages_content;
    display: flex;
    justify-content: center;
}

.Stages_title {
    grid-area: Stages_title;

    text-align: center;
    color: #1A1F24;
    font-size: 3vh;
    font-family: Tahoma;
    font-weight: 700;
    line-height: 5vh;
    word-wrap: break-word;
}

.TechnicalSupport_arrow {
    cursor: pointer;
    align-self: center;
}

.TechnicalSupport_arrow img {
    width: 7vh;
    height: 7vh;
}

.LeftArrow {
    grid-area: LeftArrow;
    justify-self: right;
}

.RightArrow {
    grid-area: RightArrow;
}