/* The overlay covers the whole screen */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* The popup box itself */
.popup {
  background-color: var(--color_aquamarine_50);
  padding: 2vh;
  border-radius: 3vh;
  max-width: 35vw;
  width: 100%;
  position: relative;
}

/* Content inside the popup */
.popup-content {
  margin-top: 2vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.popup-content h2{
    font-size: 2vh;
    font-family: Tahoma;
    font-weight: 700;
    margin-bottom: 3vh;
}

.contact-info {
  margin-bottom: 2vh;
}

.content-item-bg {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

.content-item-copied-p{
    opacity: 1;
    transition: opacity 1.5s ease-in;
    visibility: hidden; /* Ensures the element is removed from the flow */

    color: var(--color_black_text, 'red'); /* Переменная для динамического цвета */
    font-family: Tahoma, sans-serif;
    font-weight: normal; /* 'normal' эквивалентно 'regular' */
    font-size: 1.5vh;
}

.content-item-copied-p.visible{
    opacity: 0;
    visibility: visible; /* Makes the element visible */
}

.contact-item {
  display: flex;
  justify-content: left;
  align-items: center;
  border: 0.1vh solid var(--color_aquamarine_600);
  border-radius: 0.5vh;
  padding: 1vh;
  width: 11vw;
}

.contact-item .icon {
  width: 2vh;
  height: 2vh;
  margin-right: 0.5vw;
}

.contact-item span{
    text-align: center;
    color: var(--color_black_text, 'red'); /* Переменная для динамического цвета */
    font-family: Tahoma, sans-serif;
    font-weight: normal; /* 'normal' эквивалентно 'regular' */
    font-size: 1.5vh;
}

.copy-btn {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
}

/* Share icons */
.share-icons {
  display: flex;
  gap: 0.5vw;
  margin-bottom: 2vh;

  color: var(--color_black_text, 'red'); /* Переменная для динамического цвета */
  font-family: Tahoma, sans-serif;
  font-weight: normal; /* 'normal' эквивалентно 'regular' */
  font-size: 2vh;

  align-items: center;
}

.share-icons p {
  width: 14vw
}

.social-icon {
  width: 3.6vh;
  height: 3.6vh;
  cursor: pointer;
}

/* Buttons */
.popup-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1vw;
}

.cancel-btn {
  background: transparent;
  border: 1px solid #209fbb;
  color: #209fbb;
  border-radius: 0.5vh;
  padding: 1vh 2vh;
  cursor: pointer;
  font-size: 1.5vh;
  font-family: Tahoma;
  font-weight: 700;
}

.whatsapp-btn {
  background: var(--color_aquamarine_500);
  border: none;
  color: white;
  border-radius: 0.5vh;
  padding: 1vh 3vh;
  cursor: pointer;
  font-size: 1.8vh;
  font-family: Tahoma;
  font-weight: 700;
}

.whatsapp-btn img{
    width: 2vh;
    height: 2vh;
    margin: 0 0 0 0.5vw;
}

.data-copy-holder {
  gap: 1.5vh;
  display: grid;
  margin-bottom: 2vh;
}