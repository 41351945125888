.WhyWeTrusted_background {
    width: 100%;
    height: 100vh;
    background-size:cover
}

.WhyWeTrusted_container {
    height: 100%;
    margin: 0 30%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "WhyWeTrusted_Header"
        "WhyWeTrusted_Points"
        "WhyWeTrusted_Footer";
}

.WhyWeTrusted_Points_P1 {
    background-color: var(--color_aquamarine_400);
}

.WhyWeTrusted_Points_P2 {
    background-color: var(--color_aquamarine_500);
}

.WhyWeTrusted_Points_P3 {
    background-color: var(--color_aquamarine_600);
}

.WhyWeTrusted_Points {
    height: 100%;
    justify-self: center;
    align-self: center;
    grid-area: WhyWeTrusted_Points;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.WhyWeTrusted_Points-body {
    min-height: 30%;
    display: grid;
    grid-template-columns: 0.36fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        "WhyWeTrusted_point-number-bg WhyWeTrusted_point-text-bg";
}

.WhyWeTrusted_point-number-bg {
    display: flex;
    grid-area: WhyWeTrusted_point-number-bg;
    clip-path: polygon(22.73% 0%, 100% 0%, 77.27% 100%, 0% 100%);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.WhyWeTrusted_point-number-bg-img {
    z-index: 1;
    position: absolute;
}

.WhyWeTrusted_point-number-bg p {
    position: absolute;
    width: 9vh;
    height: 9vh;
    text-align: center;
    color: var(--color_white);
    font-size: 7vh;
    font-family: Tahoma;
    font-weight: 400;
    word-wrap: break-word;
    z-index: 2;
}

.WhyWeTrusted_point-text-bg {
    display: flex;
    grid-area: WhyWeTrusted_point-text-bg;
    clip-path: polygon(8.33% 0%, 100% 0%, 88.33% 100%, 0% 100%);
    margin-left: -4%;
    align-items: center;
}

.WhyWeTrusted_point-text-bg p {
    margin-left: 8%;
    margin-right: 8%;
    margin-top: 10px;
    /* height: 100%; */
    margin-bottom: 10px;
    text-align: center;
    color: var(--color_white);
    font-size: 2.3vh;
    font-family: Tahoma;
    font-weight: 500;
    word-wrap: break-word;
}


.WhyWeTrusted_point-text-bg-img {
    z-index: 1;
    position: absolute;
}

.WhyWeTrusted_Header {
    justify-self: center;
    grid-area: WhyWeTrusted_Header;

    margin-top: 10vh;
    margin-bottom: 7vh;

    height: 6vh;
    text-align: center;
    color: var(--color_white);
    font-size: 3vh;
    font-family: Tahoma;
    font-weight: 700;
    line-height: 4vh;
    word-wrap: break-word
}

.WhyWeTrusted_Footer {
    justify-self: center;
    grid-area: WhyWeTrusted_Footer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.WhyWeTrusted_reach_as_button {
    background-color: var(--color_aquamarine_500);
    color: var(--as_header_reactUsTextColor);
    border: none;
    cursor: pointer;
    border-radius: 1vh;
    /* Скругление углов */
    width: 11vw;
    /* Занимает всю ширину родительского элемента */
    height: 6vh;
    /* Занимает всю высоту родительского элемента */
    box-sizing: border-box;
    /* Учитываем отступы в расчёт ширины и высоты */
    padding: 8px 16px;
    /* Паддинг кнопки */
    font-family: Tahoma, sans-serif;
    /* Начертание Regular (обычное) */

    text-align: center;
    color: var(--color_white);
    font-size: 1.7vh;
    font-family: Tahoma;
    font-weight: 700;
}

/*.WhyWeTrusted_point-number-bg*/