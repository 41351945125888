.ExperienceCard-background {
    width: 100%;
    height: 100vh;
    background-size: cover
  }

.ExperienceCard_container {
    margin: 0 15%;
    display: grid;
    justify-content: center;
}

.ExperienceCard_container img{
    height: 50vh;
}

.ExperienceCard-title {
    margin-top: 10vh;
    margin-bottom: 14vh;

    text-align: center;
    color: var(--color_black_text);
    font-size: 3vh;
    font-family: Tahoma;
    font-weight: 700;
    line-height: 4vh;
}
  