.grid-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.start-1 {
    grid-column: 1 / span 4;
    /* Текст на первой части */
}

.start-1 img{
    height: 6vh;
}

.start-5 {
    grid-column: 5 / span 3;
    /* Компонент на пятой части */
}

.start-8 {
    grid-column: 8 / span 3;
    /* Компонент на восьмой части */
}

.start-11 {
    grid-column: 11 / span 2;
    justify-content: flex-end;

    padding: 1vh 0; /* Отступы сверху и снизу по 20px */
    box-sizing: border-box; /* Учитываем отступы в расчёт ширины и высоты */
}

.action-button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 1vh; /* Скругление углов на 10 пикселей */
    width: 100%; /* Заставляем кнопку занять всю ширину родительского элемента */
    height: 100%; /* Заставляем кнопку занять всю высоту родительского элемента */
    box-sizing: border-box; /* Учитываем отступы в расчёт ширины и высоты */
}

.action-button:hover {
    background-color: #0056b3;
}

/* styles.css */

.header {
    height: 8vh;
    display: grid;
    grid-template-columns: repeat(12, 1fr); /* Деление на 12 частей */
    padding-left: var(--loc_leftPaddingPrc);
    padding-right: var(--loc_leftPaddingPrc);
    background-color: var(--as_header_backgroundColor)
}

.reach-us-button {
    background-color: var(--as_header_reactUsButtonColor);
    color: var(--as_header_reactUsTextColor);
    border: none;
    cursor: pointer;
    border-radius: 1vh; /* Скругление углов */
    width: 100%; /* Занимает всю ширину родительского элемента */
    height: 100%; /* Занимает всю высоту родительского элемента */
    box-sizing: border-box; /* Учитываем отступы в расчёт ширины и высоты */
    padding: 8px 16px; /* Паддинг кнопки */
    font-family: Tahoma, sans-serif; /* Шрифт текста кнопки */
    font-size: 1.7vh; /* Размер шрифта текста кнопки */
    font-weight: 400; /* Начертание Regular (обычное) */
}
