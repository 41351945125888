/* Общие стили */

.AreasOfActivityCard_bg {
    width: 100%;
    height: 100vh;
    position: relative;
}

.Background {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #197C91 13%, #25B9DA 85%);
}

.CardTitle {
    width: 100%;
    height: 6vh;
    position: absolute;
    top: 10vh;
    text-align: center;
    color: white;
    font-size: 3vh;
    font-family: Tahoma;
    font-weight: 700;
    word-wrap: break-word;
}

.ClosetImage {
    width: 70%;
    height: 70vh;
    margin: 0 15%;
    position: absolute;
    top: 18.8vh;
}

.ItemsContainer {
    width: 64%;
    height: 10vh;
    margin: 0 18%;
    grid-template-columns: repeat(4, 1fr); /* 4 колонки одинаковой ширины */
    grid-template-rows: 1fr; /* Одна строка */
    display: grid;
    justify-content: space-between;
    position: absolute;
    top: 24.9vh;
}

.ItemWrapper {
    flex: 1 1 0;
    margin: 0 3.5vh;
}

.ItemComponent {
    padding: 1vh 0;
    background: #209FBB;
    border-radius: 1vh;
    justify-content: center;
    align-items: center;
    gap: 1vh;
    display: flex;
    width: 100%;
    height: 100%;
}

.ItemText {
    text-align: center;
    color: white;
    font-size: 2.4vh;
    font-family: Tahoma;
    font-weight: 700;
    line-height: 4vh;
    word-wrap: break-word;
}
