.slider-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100vh;
}

.slider-container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    grid-template-rows: 1fr 5fr 1fr;
    align-items: center;
    justify-items: center;
    width: 60%;
    height: 100%;

    margin-left: 20%;
    margin-right: 20%;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0; /* Фон будет позади остального контента */
    
    background-color: var(--as_we_suggest_bgColor);
    opacity: 70%;
}

.arrow, .slide-content-wrapper {
    position: relative; /* Относительно фона, чтобы элементы были поверх него */
    z-index: 1;
}

.arrow {
    cursor: pointer;
    grid-row: 2;
}

.arrow img {
    width: 7vh;
    height: 7vh;
}

.slide-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    grid-row: 2;
    height: 100%;
}

.slide-wrapper{
    background-color: var(--as_we_suggest_cardColor);
    border-radius: 6.5vh;
    padding: 16px;
    height: 100%;
    width: 100%;
    padding-left: 2vh;
}

.slider-wrapper-header{
    margin-top: 10vh;

    grid-row: 1;
    grid-column: 2;

    height: 6vh; 
    text-align: center;
    color: var(--as_we_suggest_textColor);
    font-size: 3vh; 
    font-family: Tahoma; 
    font-weight: 700; 
    word-wrap: break-word
}

.slide-content {
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-left: 3vw;
    width: 80%;
    /* height: 45%; */
}

.slide-content h2 {
    margin-bottom: 16px;
    color: var(--as_we_suggest_textColor);
    font-size: clamp(2vh, 3vh, 3vh); 
    font-family: Tahoma; 
    font-weight: 700; 
    /* line-height: 40px;  */
    word-wrap: break-word
}

.slide-content ul {
    padding: 0;
    
    color: var(--as_we_suggest_textColor);
    font-size: 3vh; 
    font-family: Tahoma; /*TODO: Inter*/
    font-weight: 400;  
    word-wrap: break-word;
    line-height: 5vh;
}

.progress-bar {
    margin-top: 30px;
    display: flex;
    gap: 1vh;
}

.dot {
    width: 1.5vh;
    height: 1.5vh;
    border-radius: 50%;
    background-color: var(--as_we_suggest_activeDotColor);
    cursor: pointer;
}

.dot.active {
    background-color: var(--as_we_suggest_notActiveDotColor);
}
