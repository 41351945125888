.ImageCard-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.ImageCard-left-image {
    position: absolute;
    left: 0;
    height: 100%;
}

.ImageCard-left-image img {
    height: 100%;
    object-fit: cover;
}

.ImageCard-full-image {
    width: 100%;
    height: 100%;
}

.ImageCard-full-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ImageCard-Frame6 {
    height: 34.3vh;
    width: 50%;
    left: 30vh;
    top: 30vh;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4.5vh;
    display: inline-flex;
}

.ImageCard-Frame40 {
    align-self: stretch;
    height: 20vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5vh;
    display: flex;
}

.ImageCard-Frame40 div:first-child {
    align-self: stretch;
    color: white;
    font-size: 5vh;
    font-family: Tahoma, sans-serif;
    font-weight: 700;
    word-wrap: break-word;
}

.ImageCard-Frame40 div:last-child {
    align-self: stretch;
    color: white;
    font-size: 3vh;
    font-family: Tahoma, sans-serif;
    font-weight: 700;
    word-wrap: break-word;
}

.ImageCard-Frame41 {
    align-self: stretch;
    height: 9vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 3.5vh;
    display: flex;
}

.ImageCard-Frame41 div {
    align-self: stretch;
    color: white;
    font-size: 3vh;
    font-family: Tahoma, sans-serif;
    font-weight: 500;
    word-wrap: break-word;
}