.OurPrinciplesCard-principles-container {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #BEEAF4;
}

.OurPrinciplesCard-principles-title {
  font-size: 3vh;
  font-family: Tahoma;
  font-weight: 700;
  margin: 0 auto;
}

.OurPrinciplesCard-cards-row {
  display: flex;
  justify-content: space-between;
  width: 70%;
  align-items: stretch;
  margin: 0 auto;
  gap: 2vw; 
  box-sizing: border-box;
  padding: 20px; 
}

.OurPrinciplesCard-card {
  flex: 1;
  margin: 0 15px;
}